<template>
  <div>
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>
    <div class="page-wrapper">
      <div class="content">
        <div class="row">
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
            <div class="dash-count">
              <div class="dash-counts">
                <h4>{{ analysis.subscribers.active }}</h4>
                <h5>Active Subscribers</h5>
              </div>
              <div class="dash-imgs">
                <vue-feather type="user-check"></vue-feather>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
            <div class="dash-count das1">
              <div class="dash-counts">
                <h4>{{ analysis.subscribers.inactive }}</h4>
                <h5>Inactive Subscribers</h5>
              </div>
              <div class="dash-imgs">
                <vue-feather type="user-x"></vue-feather>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
            <div class="dash-count das2">
              <div class="dash-counts">
                <h4>{{ analysis.totalCheckIn }}</h4>
                <h5>Total Check In</h5>
              </div>
              <div class="dash-imgs">
                <vue-feather type="book-open"></vue-feather>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
            <div class="dash-count das3">
              <div class="dash-counts">
                <h4>{{ analysis.totalCheckOut }}</h4>
                <h5>Total Check Out</h5>
              </div>
              <div class="dash-imgs">
                <vue-feather type="download"></vue-feather>
              </div>
            </div>
          </div>
        </div>

        <!-- Chart -->
        <!-- <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Transactions</h5>
            </div>
            <div class="card-body">
              <apexchart
                type="line"
                height="350"
                :options="mixedChartAreaChart.mixedChart"
                :series="mixedChartAreaChart.series"
              />
            </div>
          </div>
        </div> -->
        <!-- /Chart -->
        <!-- Button trigger modal -->

        <filter-table
          :extra-class="['table', 'datanew']"
          :columns="userAnalysis.columns"
          :data="userAnalysis.recentUsers"
          :filters="userAnalysis.filters"
        >
          <template #header>
            <h4>Recent Users</h4>
          </template>
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'name'">
              <div class="userimgname gap-2 align-items-center">
                <a href="javascript:void(0);" class="userslist-img bg-img gap-2">
                  <Avatar
                    :src="record.profile_picture"
                    :alt="record.name"
                    img-class="user-image"
                  />
                </a>
                <div>
                  <a href="javascript:void(0);">{{ record.name }}</a>
                </div>
              </div>
            </template>
            <template v-else-if="column.key === 'status'">
              <div>
                <span :class="getBadgeClass(record.is_active)">{{ record.status }}</span>
              </div>
            </template>
            <template v-else-if="column.key === 'action'">
              <td class="action-table-data">
                <div class="edit-delete-action">
                  <a class="me-2 p-2 mb-0" href="javascript:void(0);">
                    <vue-feather type="eye" class="action-eye"></vue-feather>
                  </a>
                  <a
                    class="me-2 p-2 mb-0"
                    data-bs-toggle="modal"
                    data-bs-target="#edit-units"
                  >
                    <i data-feather="edit" class="feather-edit"></i>
                  </a>
                  <a class="me-2 confirm-text p-2 mb-0" href="javascript:void(0);">
                    <i data-feather="trash-2" class="feather-trash-2"></i>
                  </a>
                </div>
              </td>
            </template>
          </template>
        </filter-table>
      </div>
    </div>
  </div>
</template>

<script setup>
// import { chart } from "@/utils/transactionChart";
import { computed, onMounted, ref } from "vue";
// import { basicAreaChart } from "./data";
import Swal from "sweetalert2";
import { useDashboardStore } from "@/store/dashboardStore";
import { useTransactionStore } from "@/store/transactionStore";
import { mapStores } from "pinia";
import { useDevotionStore } from "@/store/devotionStore";
import { useUserStore } from "@/store/userStore";
import Avatar from "@/components/avatar.vue";
import { usePlanStore } from "@/store/planStore";
import { devotionTypes } from "@/constants/enum";
import { encodeUrlData } from "../devotion/utils/data";

// store
const planStore = usePlanStore();
const dashboardStore = useDashboardStore();
const devotionStore = useDevotionStore();
const userStore = useUserStore();
const transactionStore = useTransactionStore();
const mixedChartAreaChart = ref({});

// data
const recentCheckedIn = computed(() => devotionStore.devotions.slice(0, 5));
const analysis = ref({
  subscribers: { active: 0, inactive: 0 },
  totalCheckIn: 0,
  totalCheckOut: 0,
});

const userAnalysis = ref({
  filters: ["name", "mobile_number"],
  columns: ref([
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
      sorter: {
        compare: (a, b) => {
          a = a.name.toLowerCase();
          b = b.name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      sorter: {
        compare: (a, b) => {
          a = a.phone.toLowerCase();
          b = b.phone.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: {
        compare: (a, b) => {
          a = a.email.toLowerCase();
          b = b.email.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: {
        compare: (a, b) => {
          a = a.status.toLowerCase();
          b = b.status.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   sorter: true,
    // },
  ]),
  recentUsers: computed(() => {
    const reversedUsers = [...userStore.users].reverse(); // Make a copy of users array and reverse it
    return reversedUsers.slice(0, 5);
  }),
});

const showConfirmation = () => {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
    confirmButtonClass: "btn btn-primary",
    cancelButtonClass: "btn btn-danger ml-1",
    buttonsStyling: false,
  }).then((result) => {
    if (result.value) {
      Swal.fire({
        icon: "success",
        title: "Deleted!",
        text: "Your file has been deleted.",
        confirmButtonClass: "btn btn-success",
      });
    }
  });
};

function getBadgeClass(status) {
  return `badge badge-line${status ? "success" : "danger"}`;
}
function devotionDetailsUrl(devotion) {
  return `/devotional/view/${encodeUrlData(
    devotionTypes.dailyDevotion,
    devotion.date,
    devotion.id
  )}`;
}
onMounted(async () => {
  // if (recentCheckedIn.value.length == 0) {
  //   await devotionStore.fetchDevotions();
  // }
  if (userAnalysis.value.recentUsers.length == 0) {
    await userStore.fetchUsers();
  }
  // planStore.fetchSubscriptionPlans();
  analysis.value = await dashboardStore.fetchAnalysis();
  // await dashboardStore.fetchTransactionChart(new Date().toISOString().slice(0, 7));
  // console.log(dashboardStore.transactionChart.series[1])
  // mixedChartAreaChart.value = chart(
  //   dashboardStore.transactionChart.labels,
  //   dashboardStore.transactionChart.series
  // );
});
</script>
<style>
.round-avatar {
  width: 50px;
  height: 50px;
  max-width: 100%;
  margin-right: 0;
  border-radius: 20%;
}
</style>
