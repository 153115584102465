import { api } from "@/configs";
import { defineStore } from "pinia";
import { apiUrls } from "@/constants";

// export function processUser(user) {
//   return {};
// }
export const useAdminStore = defineStore("admin", {
  state: () => {
    return {
      admins: [],
      userTransactionHistory: [],
    };
  },
  actions: {
    async changePassword(new_password, current_password) {
      try {
        const password = { new_password, current_password };
        const response = await api.patch(
          `${apiUrls.admin}/changePassword`,
          password
        );
        if (response.data.status) return response.data.status;
        else return response.data.status;
      } catch (error) {
        console.log(error);
      }
    },

    async addAdmin(admin) {
      try {
        await api.post(apiUrls.admin, admin, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        await this.fetchAdmins();
        Swal.fire("User Added Successfully", "", "success");
        return true;
      } catch (error) {
        return false;
      }
    },

    async fetchAdmins() {
      try {
        const response = await api.get(apiUrls.admin);
        this.admins = response.data.data;
      } catch (error) {}
    },

    async updateAdminStatus(id, user) {
      try {
        const response = await api.patch(
          `${apiUrls.admin}/${id}`,
          // { _method: "PATCH", ...user },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        // update the use to skip fetching all user again
        // const updatedUserIndex = this.users.findIndex((user) => user.id == id);
        // this.users[updatedUserIndex] = processUser(response.data.data);
        // Swal.fire(`Admin Updated Successfully`, "", "success");
        return true;
      } catch (error) {
        log(error);
        return false;
      }
    },

    async updateAdminDetails(adminId, data) {
      try {
        await api.post(
          `${apiUrls.admin}/update/${adminId}`,
          { _method: "PATCH", ...data },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        Swal.fire(`Admin Updated Successfully`, "", "success");
        await this.fetchAdmins();
        return true;
      } catch (error) {
        log(error);
        return false;
      }
    },

    async deleteAdmin(adminId) {
      try {
        await api.delete(`${apiUrls.admin}/${adminId}`);

        const deletedAdminIndex = this.admins.findIndex(
          (admin) => admin.id == adminId
        );
        delete this.admins[deletedAdminIndex];
        Swal.fire(`Admin Deleted Successfully`, "", "success");
        return true;
      } catch (error) {
        return false;
      }
    },
  },
});
