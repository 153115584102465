import { Envs } from "./enum";

const env = process.env.NODE_ENV;
const isDevelopment = env !== Envs.production;

let apiUrl = "https://prolife.dev";
switch (env) {
  case Envs.demo:
    apiUrl = "https://prolifenet.gtcomnet.com";
    break;
  case Envs.production:
    apiUrl = "https://tunnel5.prolifefitness.com.ng";
    break;
}

const baseUrl = `${apiUrl}/api/v1/admin/`;
//
const url = (path) => baseUrl + path;
const apiUrls = {
  login: url("login"),
  profile: url("profile"),
  analysis: url("analysis"),
  comments: url("comments"),
  devotion: url("devotion"),
  word: url("devotion/wod"),
  plans: url("plans"),
  categories: url("categories"),
  createCategories: url("categories/create"),
  updateCategories: url("categories/update"),
  deleteCategories: url("categories/delete"),
  user: url("users"),
  admin: url("admin"),
  updates: url("updates"),
  subscription: url("subscription"),
  prolife: url("prolife"),
  transactions: url("transactions"),
  products: url("products"),
};

const version = "1.0.6";

export { baseUrl, apiUrls, isDevelopment, version };
